<template>
  <div class="animate__animated animate__fadeIn">
    <div class="larger-dialog-tablet-up profile-pages mb-5">
      <div
        id="profile"
        class="app-container edit-profile"
      >
        <TopBar
          v-if="profile"
          :profile="profile"
          :route="route"
          :is-loading="dataLoading"
        />
        <v-container fluid>
          <v-row justify="center">
            <v-col
              cols="12"
              lg="10"
              class="wides"
            >
              <v-container fluid>
                <v-row justify="center">
                  <v-col
                    cols="12"
                    md="4"
                    order-md="1"
                    order="2"
                  >
                    <v-row>
                      <v-col cols="12">
                        <AccountInfoCard
                          ref="accountInfoCard"
                          :save-content="saveContent"
                          :create-alert="createAlert"
                          :route="route"
                          @update:isLoading="handleLoading"
                        />
                      </v-col>
                      <v-col
                        class="ipu-div"
                        cols="12"
                      >
                        <DiscPayment
                          :is-personality="isPersonality"
                          :is-motivations="isMotivations"
                          :create-alert="createAlert"
                          :ipu-articles="ipuArticles"
                          :unfinished-link="unfinishedLink"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    cols="12"
                    md="8"
                    order-md="2"
                    order="1"
                  >
                    <v-row>
                      <v-col
                        id="experience-container"
                        class="table-div"
                        cols="12"
                      >
                        <Description
                          v-if="profile"
                          :profile="profile"
                        />
                        <hr class="mb-3 mt-5">
                        <Experiences
                          v-if="profile"
                          :profile="profile"
                          :route="route"
                          @save-content="saveContent"
                          @delete-item="deleteSkill"
                        />
                        <hr class="my-3">
                        <Skills
                          v-if="profile"
                          :profile="profile"
                          :route="route"
                          @save-content="saveContent"
                          @delete-item="deleteSkillName"
                        />
                        <hr class="my-3">
                        <Languages
                          v-if="profile"
                          :profile="profile"
                          @save-content="saveContent"
                          @delete-item="deleteSkill"
                        />
                        <hr class="my-3">
                        <Education
                          v-if="profile"
                          :profile="profile"
                          @save-content="saveContent"
                          @delete-item="deleteSkill"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-dialog
        id="intro"
        v-model="introDialog"
      >
        <div
          is="CustomDialog"
          title-icon="account_circle"
          :title="$t('Pages.Profile.its_time')"
          :second-title="$t('Pages.Profile.let_everyone')"
          :text="$t('Pages.Profile.a_complete')"
        >
          <div
            slot="buttons"
            class="button-container"
          >
            <v-btn
              text
              @click="introDialog = false"
              v-text="$t('Common.close')"
            />
          </div>
        </div>
      </v-dialog>
      <v-dialog
        id="relevant-info"
        v-model="relevantInfoDialog"
      >
        <CustomDialog
          title-icon="account_circle"
          :title="$t('Pages.Profile.its_time_2')"
          :text="$t('Pages.Profile.fill_in')"
        >
          <div
            slot="buttons"
            class="button-container"
          >
            <v-btn
              id="close-relevant-info-dialog"
              text
              rounded
              @click="relevantInfoDialog = false"
              v-text="$t('Common.ok')"
            />
          </div>
        </CustomDialog>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'

import CustomDialog from '@/common/CustomDialog.vue'

import AccountInfoCard from '@/pages/profile/edit/components/AccountInfoCard.vue'
import TopBar from '@/pages/profile/edit/components/EditProfileTopBar.vue'

import Languages from '@/pages/profile/edit/components/EditProfileLanguages.vue'
import Education from '@/pages/profile/edit/components/EditProfileEducation.vue'
import Experiences from '@/pages/profile/edit/components/EditProfileExperience.vue'
import Description from '@/pages/profile/edit/components/EditProfileDescription.vue'
import Skills from '@/pages/profile/edit/components/EditProfileSkills.vue'
// import PageLoader from '@/common/PageLoader.vue'
import DiscPayment from '@/pages/profile/edit/components/disc/DiscPayment.vue'
import { formatDate } from '@/config/formatDate.js'
import EventBus from '@/common/EventBus.js'
import { UPDATE_COMPLETION } from '@/store/actions.type'

export default {
  name: 'ProfileEditView',
  metaInfo () {
    return {
      title: this.brandName
    }
  },
  components: {
    Description,
    CustomDialog,
    // PageLoader
    DiscPayment,
    AccountInfoCard,
    TopBar,
    Languages,
    Education,
    Experiences,
    Skills
  },
  props: {
    createAlert: {
      type: Function,
      default: function () {}
    },
    route: {
      type: String,
      default: 'profile'
    }
  },
  data () {
    return {
      formatDate,
      pageLoading: false,
      dataLoading: false,
      error: null,
      relevantInfoDialog: false,
      newLocationDialog: false,
      firstName: this.$store.state.firstName,
      lastName: this.$store.state.lastName,
      isMotivations: false,
      isPersonality: false,
      introDialog: false,
      ipuArticles: {},
      unfinishedLink: null
    }
  },
  computed: {
    ...mapState(['brandName', 'profile'])
  },
  watch: {
    $route: 'fetchData'
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      if (
        this.profile &&
        this.profile.ipu &&
        this.profile.ipu.disc &&
        this.profile.ipu.disc.length > 0
      ) {
        // console.log('init')
        // console.log('this.profile.ipu', this.profile.ipu)
        this.handlePersonality()
      }
      if (
        this.profile &&
        !this.profile.experiences.length &&
        !this.profile.skills.length &&
        !this.profile.educations.length &&
        !this.profile.languages.length
      ) {
        this.relevantInfoDialog = true
      }
      this.pageLoading = false
      if (this.profile && !this.profile.hasOldButNotNewLocation) {
        this.newLocationDialog = true
      }
      this.unfinishedLink = this.profile.ipu.surveyLink || this.profile.ipu.link
    },
    saveContent (value, name, keepOpen) {
      let route = ''
      let method = ''
      if (
        this.profile[name] &&
        this.profile[name][value.index] &&
        this.profile[name][value.index].id
      ) {
        route = this.route + '/' + name + '/' + this.profile[name][value.index].id
        value.id = this.profile[name][value.index].id
        method = 'put'
      } else {
        route = this.route + '/' + name
        method = 'post'
      }
      this.$http[method](route, value).then(
        (res) => {
          if (name === 'experiences') {
            this.profile.experiences = res.data.profile.experiences
            this.profile.skills = res.data.profile.skills
          } else if (name === 'skills/star') {
            this.profile.skills = res.data.profile.skills
          } else {
            this.profile[name] = res.data.profile[name]
          }
          this.$store.dispatch(UPDATE_COMPLETION, {
            completion: res.data.profile.completion
          })
          EventBus.$emit('saved' + name, keepOpen)
        },
        (err) => {
          console.error(err)
          if (err.status === 409) {
            const error = 'Duplicate ' + name + 's, this can not be saved'
            EventBus.$emit('duplicateErrors' + name, error)
          } else {
            this.$root.$emit('errorMessage', err.response)
          }
        }
      )
    },
    deleteSkillName (name) {
      this.$http
        .delete(this.route + '/skills/' + encodeURIComponent(name))
        .then(
          (res) => {
            this.profile.experiences = res.data.profile.experiences
            this.profile.skills = res.data.profile.skills
            this.$store.dispatch(UPDATE_COMPLETION, {
              completion: res.data.profile.completion
            })
            EventBus.$emit('deleteDialogskill')
          },
          (err) => {
            console.error(err)
            this.$root.$emit('errorMessage', err.response)
          }
        )
    },
    deleteSkill (tag, type) {
      const error = type + 'Errors'
      this[error] = []
      if (!tag.id) {
        // if no id, it has not been saved to the backend - just delete that mofo
        return this.profile[type].splice(this.profile[type].indexOf(tag), 1)
      }
      this.$http.delete(this.route + '/' + type + '/' + tag.id).then(
        (res) => {
          if (type === 'experience') {
            this.profile.experiences = res.data.profile.experiences
            this.profile.skills = res.data.profile.skills
          } else {
            this.profile[type] = res.data.profile[type]
          }
          this.$store.dispatch(UPDATE_COMPLETION, {
            completion: res.data.profile.completion
          })
          EventBus.$emit('deleteDialog' + type)
        },
        (err) => {
          console.error(err)
          this.$root.$emit('errorMessage', err.response)
        }
      )
    },
    handlePersonality () {
      // console.log('handlePersonality')
      this.isMotivations = true
      this.isPersonality = true
    },
    handleLoading(isLoading) {
      this.dataLoading = isLoading;
    }
  }
}
</script>
<style>
.tabs__container {
  transform: translateX(0) !important;
}
</style>
