<template>
  <div
    class="d-flex flex-column animate__animated animate__fadeIn"
    style="width: 100%"
  >
    <!-- Debug Information -->
    <div v-if="debug">
      <h3>Debug Information</h3>
      <p>OrderRef: {{ orderRef }}</p>
      <p>Count: {{ count }}</p>
      <p>isAnyMobileDevice: {{ isAnyMobileDevice() }}</p>
      <p>sameDeviceUrl: {{ sameDeviceUrl }}</p>
      <p>Registration: {{ registration }}</p>
      <p>Error: {{ error }}</p>
    </div>
    <v-alert
      v-if="alert.visible"
      class="rounded-xl"
      transition="scale-transition"
      :type="alert.type"
      dark
    >
      {{ alert.text }}
    </v-alert>
    <h2
      class="mb-6"
      :class="{ 'text-h6': $vuetify.breakpoint.smAndDown }"
    >
      <v-btn
        id="back-arrow"
        class="ma-1"
        rounded
        text
        outlined
        @click="navigateToLogin"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      Skapa Företagskonto
    </h2>
    <v-form
      ref="registerCompanyForm"
      v-model="registerCompanyFormValid"
      class="d-flex flex-column"
      @submit.prevent="onSubmitRegister"
    >
      <v-text-field
        id="org-input"
        v-model="organisationNumber"
        class="mb-4"
        label="Organisationsnummer"
        color="accent"
        :rules="[rules.required, rules.organisationNumber]"
        :loading="loading"
        type="tel"
        hint="Ange organisationsnummer"
        persistent-hint
        outlined
        rounded
        required
        hide-details="auto"
      />
      <v-text-field
        id="company-name-input"
        v-model="companyName"
        class="mb-4"
        label="Företagsnamn"
        color="accent"
        :rules="[rules.required]"
        :loading="loading"
        type="text"
        hint="Ange företagsnamn"
        persistent-hint
        outlined
        rounded
        required
        hide-details="auto"
      />
      <v-text-field
        id="email-input"
        v-model="email"
        class="mb-4"
        :maxlength="50"
        :rules="[rules.email_required, rules.email]"
        color="accent"
        :label="$t('Pages.Profile.email')"
        hint="Notiser om nya uppdrag skickas hit"
        persistent-hint
        type="email"
        outlined
        rounded
        required
        hide-details="auto"
      />
      <v-text-field
        id="phone-input"
        v-model="phone"
        class="mb-4"
        :maxlength="13"
        color="accent"
        type="tel"
        hint="Hit kan du bli kontaktad"
        persistent-hint
        :rules="[rules.phone]"
        outlined
        rounded
        :label="$t('Common.phone_number')"
        hide-details="auto"
      />
      <v-btn
        v-if="!isAnyMobileDevice()"
        id="login-bankid-btn"
        type="submit"
        class="mb-6"
        light
        rounded
        depressed
        x-large
      >
        <img
          :src="bankidBlack"
          alt="BankID logo"
          style="width: 40px; margin-right: 4px"
        >
        Slutför registreringen
      </v-btn>
      <v-btn
        v-else
        id="open-bankid-btn"
        type="submit"
        class="mb-6"
        light
        rounded
        depressed
        x-large
      >
        <img
          :src="bankidBlack"
          alt="BankID logo"
          style="width: 40px; margin-right: 4px"
        >
        Öppna BankID
      </v-btn>
      <v-btn
        v-if="isAnyMobileDevice()"
        color="secondary"
        class="secondary--text mb-6"
        rounded
        outlined
        x-large
        depressed
        @click="validateAndStartBankIDOnAnotherDevice"
      >
        BankID på annan enhet
      </v-btn>
      <div class="text-subtitle-1 mb-2">
        Villkor
      </div>
      <div class="text-body-2 mb-3">
        När du registrerar ett företagskonto accepterar du GigPorts Allmänna
        (där information om behandling av personuppgifter ingår).
      </div>
      <div>
        <v-icon color="#FFB74D">
          mdi-open-in-new
        </v-icon>
        <router-link
          :to="{ name: 'tos-users' }"
          target="_blank"
          rel="noreferrer"
          v-text="$t('Footer.terms')"
        />
      </div>
      <div>
        <v-icon color="#FFB74D">
          mdi-open-in-new
        </v-icon>
        <router-link
          :to="{ name: 'privacy-policy' }"
          target="_blank"
          rel="noreferrer"
          v-text="$t('Footer.privacy')"
        />
      </div>
    </v-form>
    <QRCodeDialog
      v-if="showQRCodeDialog"
      :show="showQRCodeDialog"
      :qrcode-type="'register'"
      :registration="registration"
      @cancel-login="onCancelLogin($event)"
    />
  </div>
</template>

<script>
import {
  isAnyMobileDevice,
  getLaunchUrlByDevice
} from "@/utils/utils";
import bankidBlack from "@/assets/img/bankid_black.svg";
import { rules } from "@/config/validation-rules.js";
import {
  AUTH_REGISTER_MOBILE_COLLECT,
  AUTH_REGISTER_MOBILE_START,
  AUTH_REGISTER_COLLECT,
  AUTH_REGISTER_START
} from "@/store/actions.type";
import QRCodeDialog from "./QRCodeDialog.vue";

export default {
  name: "RegisterCompany",
  components: {
    QRCodeDialog,
  },
  data() {
    return {
      isAnyMobileDevice,
      getLaunchUrlByDevice,
      bankidBlack,
      registerCompanyFormValid: true,
      showQRCodeDialog: false,
      organisationNumber: "",
      companyName: "",
      orderRef: "",
      rules,
      email: "",
      phone: "",
      loading: false,
      alert: {
        visible: false,
        text: "",
        type: "error",
      },
      sameDeviceUrl: null,
      registration: {
        email: "",
        userType: "customer",
        phone: "",
        companyName: "",
        orgNumber: "",
      },
      debug: false, // Enable debug information
      count: 0, // Debug counter
      error: null, // Debug error
    };
  },
  watch: {
    "$route.query.orderRef": "handleOrderRefChange",
    sameDeviceUrl(newUrl) {
      if (newUrl) {
        window.location.href = newUrl;
      }
    },
  },
  mounted() {
    if (window.location.hash === "#initiated=true") {
      window.location.hash = "";
    }
    if (this.$route.query.orderRef) {
      this.count = 1;
      this.orderRef = this.$route.query.orderRef;
      this.collect(this.orderRef, this.isAnyMobileDevice());
    }
  },
  methods: {
    navigateToLogin() {
      this.$router.push({ name: "accounts-login" });
    },
    onCancelLogin(err) {
      if (err) {
        this.handleRegistrationError(err);
      }
      this.showQRCodeDialog = false;
    },
    validateAndStartBankIDOnAnotherDevice() {
      this.registration = {
        email: this.email,
        userType: "customer",
        phone: this.phone,
        companyName: this.companyName,
        orgNumber: this.organisationNumber,
      };
      if (this.$refs.registerCompanyForm.validate()) {
        this.showQRCodeDialog = true;
      }
    },
    onSubmitRegister() {
      if (this.$refs.registerCompanyForm.validate()) {
        const registration = {
          email: this.email,
          userType: "customer",
          phone: this.phone,
          companyName: this.companyName,
          orgNumber: this.organisationNumber,
        };
        this.registration = registration;
        if (this.isAnyMobileDevice()) {
          this.startRegisterCompanyMobile(registration);
        } else {
          this.startRegisterCompany(registration);
        }
      }
    },
    startRegisterCompany(registration) {
      this.$store
        .dispatch(AUTH_REGISTER_START, registration)
        .then((res) => {
          this.showQRCodeDialog = true;
          // this.$store
          //   .dispatch(AUTH_REFRESH_REGISTER_QRCODE, res.data)
          //   .then((res) => {
          //     if (res.token && res.status === "complete") {
          //       this.$store.dispatch("GET_USER");
          //       this.$router.push({ name: "dashboard" });
          //     }
          //   })
          //   .catch((err) => {
          //     this.error = err;
          //     // this.$root.$emit("errorMessage", err.response);
          //   });
        })
        .catch((err) => {
          this.handleRegistrationError(err);
          // this.$root.$emit("errorMessage", err.response);
        });
    },
    startRegisterCompanyMobile(registration) {
      // console.log("startRegisterCompanyMobile", registration);
      this.$store
        .dispatch(AUTH_REGISTER_MOBILE_START, registration)
        .then((res) => {
          // console.log("orderRef from response:", res.data.orderRef);
          let redirectUrl = window.location.href;
          redirectUrl += this.$route.query.redirect
            ? `&orderRef=${res.data.orderRef}`
            : `?orderRef=${res.data.orderRef}`;
          this.sameDeviceUrl = this.getLaunchUrlByDevice(
            res.data.autoStartToken,
            redirectUrl
          );
        })
        .catch((err) => {
          this.error = err;
          this.handleRegistrationError(err);
          // this.$root.$emit("errorMessage", err.response);
        });
    },
    handleRegistrationError(err) {
      if (err.response.data.errorCode === "409E002") {
        this.alert.text = this.$t("Validation.email_in_use");
      } else if (err.response.data.errorCode === "409E003") {
        this.alert.text = this.$t("Validation.account_with_nin");
      } else {
        this.alert.text = err.response.data.errorMessage;
      }
      this.alert.visible = true;
    },
    handleOrderRefChange(newVal) {
      if (newVal) {
        this.orderRef = newVal;
        this.collect(newVal, this.isAnyMobileDevice());
      }
    },
    collect(orderRef, isMobile) {
      // window.alert("collect" + orderRef + isMobile);
      this.count = 2;
      const path = isMobile
        ? AUTH_REGISTER_MOBILE_COLLECT
        : AUTH_REGISTER_COLLECT;
      const payload = { orderRef };
      this.$store
        .dispatch(path, payload)
        .then((res) => {
          this.count = 3;
          if (res.token && res.status === "complete") {
            this.$gtm.trackEvent({
              category: 'Growth',
              action: 'New-signup',
              label: 'New signup company',
              value: 0
            })
            this.$store.dispatch("GET_USER");
            this.$router.push({ name: "dashboard" });
          }
        })
        .catch((err) => {
          this.error = err;
          console.error('Error: ', err.response);
          if (err.status === 403 && err.data.errorCode === "403E005") {
            this.handleRegistrationError(err);
          } else {
            this.$root.$emit("errorMessage", err.response);
          }
        });
    },
  },
};
</script>