<template>
  <div>
    <v-card
      color="neutral"
      class="elevation-1 rounded-xl"
    >
      <v-container>
        <v-row
          justify="center"
          class="profile-info"
        >
          <v-col
            cols="12"
            lg="10"
          >
            <v-container fluid>
              <v-row justify="center">
                <v-col cols="12">
                  <ProfilePicture
                    v-if="profile"
                    :profile="profile"
                    :route="route"
                    :create-alert="createAlert"
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="profile-account-info"
                >
                  <h2 class="text-center">
                    {{ user.firstName }} {{ user.lastName }}
                  </h2>
                  <div class="flex-box my-2">
                    <div
                      id="add-location"
                      class="text-center add-location cursor-pointer"
                      @click="onOpenLocationDialog"
                    >
                      <v-icon
                        small
                        class="icon-link"
                      >
                        place
                      </v-icon>
                      <span class="text-decoration-underline">
                        {{
                          profile && profile.locations.length > 0
                            ? profile.locations.join(", ")
                            : $t("Pages.Search.location")
                        }}
                      </span>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>

        <div class="text-center">
          <p
            class="my-2"
            v-text="$t('Pages.Profile.intro')"
          />
          <v-btn
            id="upload-cv"
            :loading="isLoading"
            :disabled="isLoading"
            color="blue-grey"
            class="ma-2 white--text"
            @click="triggerFileInput"
          >
            {{ $t('Pages.Profile.button') }}
            <v-icon
              right
              dark
            >
              mdi-cloud-upload
            </v-icon>
          </v-btn>
          <p
            class="text-caption mt-2 text-center"
            v-text="$t('Pages.Profile.waitMessage')"
          />
          <input
            ref="fileInput"
            type="file"
            accept=".pdf"
            style="display: none"
            @change="handleFileChange"
          >
        </div>
      </v-container>
    </v-card>

    <v-dialog
      v-model="locationDialog"
      max-width="500"
    >
      <CustomDialog
        :title="$t('Pages.Search.location')"
        title-icon="room"
        class="location-card"
      >
        <EditProfileLocation
          v-if="profile"
          slot="custom"
          :route="route"
          :profile="profile"
          :create-alert="createAlert"
          @close-location-dialog="closeLocationDialog"
        />
      </CustomDialog>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CustomDialog from "@/common/CustomDialog.vue";
import EditProfileLocation from "@/pages/profile/edit/components/EditProfileLocation.vue";
import ProfilePicture from "@/pages/profile/edit/components/EditProfilePicture.vue";
import { ApiService } from "@/common/api.service";
import { SET_PROFILE } from "@/store/mutations.type";

export default {
  name: "AccountInfoCard",
  components: {
    CustomDialog,
    EditProfileLocation,
    ProfilePicture,
  },
  props: {
    createAlert: { type: Function, default: () => {} },
    route: { type: String, required: true },
  },
  data() {
    return {
      cvFile: null,
      isLoading: false,
      locationDialog: false,
    };
  },
  computed: {
    ...mapState(["profile", "user"]),
  },
  watch: {
    isLoading(newVal) {
      this.$emit('update:isLoading', newVal);
    }
  },
  methods: {
    closeLocationDialog() {
      this.locationDialog = false;
    },
    onOpenLocationDialog() {
      this.locationDialog = true;
    },
    triggerFileInput() {
      this.$refs.fileInput.value = "";
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file && file.type === "application/pdf") {
        this.cvFile = file;
        this.startUploadCV();
      } else {
        this.$root.$emit("errorMessage", "Only PDF files are allowed.");
      }
    },
    startUploadCV() {
      if (this.cvFile) {
        this.isLoading = true;
        let hasCompleted = false; // Guard to prevent further execution

        const reader = new FileReader();
        reader.readAsDataURL(this.cvFile);

        reader.onload = async () => {
          const base64PDF = reader.result.split(",")[1];

          this.$http.post("profile/import-pdf/start", { base64_pdf: base64PDF }).then((res) => {
            console.log("CV upload started: ", res);

            const poll = () => {
              if (hasCompleted) return; // Stop if already completed or failed

              this.$http
                .put("profile/import-pdf/collect", {
                  importId: res.data.importId,
                })
                .then((res) => {
                  console.log("CV upload collect: ", res);

                  if (res.data.importStatus === "failed" || res.data.importStatus === "finished") {
                    hasCompleted = true; // Mark as completed
                    this.isLoading = false;

                    // Handle status
                    if (res.data.importStatus === "finished") {
                      this.$store.commit(SET_PROFILE, res.data.profile);
                      this.$root.$emit("successMessage", "CV uploaded successfully!");
                    } else {
                      this.$root.$emit("errorMessage", res.data.error || "CV upload failed.");
                    }
                  } else {
                    // Schedule the next poll
                    setTimeout(poll, 1000);
                  }
                })
                .catch((err) => {
                  console.error("Error collecting CV: ", err);
                  if (!hasCompleted) {
                    hasCompleted = true; // Ensure no further calls
                    this.isLoading = false;
                    this.$root.$emit("errorMessage", err.response || "CV upload failed.");
                  }
                });
            };

            poll(); // Start polling
          }).catch((err) => {
            console.error("Error starting CV upload: ", err);
            this.isLoading = false;
            this.$root.$emit("errorMessage", err.response || "Failed to start CV upload.");
          });
        };

        reader.onerror = () => {
          console.error("Error reading file");
          this.$root.$emit("errorMessage", "Error reading the file. Please try again.");
          this.isLoading = false;
        };
      }
    },
    async uploadCV() {
      if (this.cvFile) {
        this.isLoading = true;
        const reader = new FileReader();
        reader.readAsDataURL(this.cvFile);

        reader.onload = async () => {
          const base64PDF = reader.result.split(",")[1];
          ApiService.uploadCV(base64PDF, (err, res) => {
            if (err) {
              console.error("Error uploading CV:", err);
              this.$root.$emit(
                "errorMessage",
                err.response || "CV upload failed."
              );
            } else {
              console.log("CV uploaded successfully:", res.data);
              this.$store.commit(SET_PROFILE, res.data.profile);
              this.$root.$emit("successMessage", "CV uploaded successfully!");
            }
            this.isLoading = false;
          });
        };

        reader.onerror = () => {
          console.error("Error reading file");
          this.$root.$emit(
            "errorMessage",
            "Error reading the file. Please try again."
          );
          this.isLoading = false;
        };
      }
    },
  },
};
</script>

<style scoped>
.mobile-overflow-x {
  overflow-x: auto;
}
</style>
