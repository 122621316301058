<template>
  <div>
    <h4
      class="resume-titles mb-3"
      v-text="$t('Pages.Profile.short_description')"
    />
    <div class="top-card mb-3">
      <v-card
        class="pa-3 rounded-xl"
        color="neutral"
      >
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-textarea
            id="profile-description"
            v-model="description"
            :maxlength="500"
            :counter="500"
            color="accent"
            class="description"
            outlined
            :label="$t('Pages.Profile.describe')"
            @focus="isEditing = true"
            @keydown="isChanged = true"
          />
          <template v-if="isEditing">
            <v-btn
              id="cancel-save-description"
              text
              @click="cancelEdit"
            >
              {{ $t('Common.cancel') }}
            </v-btn>
            <v-btn
              id="save-description"
              color="primary"
              @click="saveDescription"
            >
              {{ $t('Common.save') }}
            </v-btn>
          </template>
        </v-form>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditProfileDescription',
  props: {
    profile: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      description: this.profile.description || '',
      valid: false,
      isEditing: false,
      isChanged: false
    };
  },
  watch: {
    profile: {
      handler(newProfile) {
        this.description = newProfile.description || '';
      },
      deep: true
    }
  },
  methods: {
    cancelEdit() {
      this.isEditing = false;
      this.description = this.profile.description || '';
    },
    saveDescription() {
      // Logic to save the description
      this.isEditing = false;
      this.isChanged = false;
      // Emit an event or call a method to save the description
      this.$emit('save-description', this.description);
    }
  }
};
</script>

<style scoped>
.description {
  width: 100%;
}
</style>